import React from "react";
import * as Icon from "react-feather";
import BASE from "../configs/BASE";
import Product from "../assets/img/rover_icon/Product.svg";
import crm from "../assets/img/rover_icon/crm.svg";
import accounts from "../assets/img/rover_icon/accounts.svg";
import stocks from "../assets/img/rover_icon/stocks.svg";
import employee from "../assets/img/rover_icon/employee.svg";
import settings from "../assets/img/rover_icon/settings.svg";
import userIcon from "../assets/img/rover_icon/user.svg";
import recovery from "../assets/img/rover_icon/recovery.svg";
import circle from "../assets/img/rover_icon/circle.svg";
import distribution from "../assets/img/rover_icon/distribution.svg";
import Sales from "../assets/img/rover_icon/marketing.svg";
//import userImage from "../assets/img/portrait/small/Capture.PNG";
//import userImage2 from "../assets/img/portrait/small/masud2.jpg";

import farmergroup from "../assets/img/sidebaricons/farmersgroup.png";
import farmer from "../assets/img/sidebaricons/farmer.jpg";
import farmerTraining from "../assets/img/sidebaricons/farmersTraining.png";
import enterprenueshipTraining from "../assets/img/sidebaricons/enterprenueshipTraining.jpg";
import tot from "../assets/img/sidebaricons/TOT.jpg";
import demonstration from "../assets/img/sidebaricons/demonstration.jpg";
import asset from "../assets/img/sidebaricons/asset.jpg";
import machineries from "../assets/img/sidebaricons/machineries.jpg";
import receiveVoucher from "../assets/img/sidebaricons/receiveVoucher.png";
import paymentVoucher from "../assets/img/sidebaricons/expense.jpg";
import locationIcon from "../assets/img/sidebaricons/location.jpg";
import userIcon2 from "../assets/img/sidebaricons/user.jpg";

const divStyle = {
  marginRight: "10px",
  width: "25px",
  height: "25px",
};
const circleStyle = {
  marginRight: "10px",
  width: "15px",
  height: "15px",
};

const getAuth = () => {
  let authenticatedUser = localStorage.getItem("user");
  if (authenticatedUser) {
    let user = JSON.parse(authenticatedUser);
    if (user && user.auth && user.auth.isAuth) {
      localStorage.setItem("userRole", "Allow");
    }
  }
};
//let Img = <img src={userImage}/>
getAuth();

const navigationConfig = [
  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/",
  },

  {
    id: "frmgp",
    title: "Farmer's Group",
    type: "item",
    icon: <img src={farmergroup} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/frmgp",
  },

  {
    id: "frmlst",
    title: "Farmer's List",
    type: "item",
    icon: <img src={farmer} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/frmlst",
  },
  ,

  {
    id: "frmlstup",
    title: "Add From Excel",
    type: "item",
    icon: <img src={farmer} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/frmlstup",
  },
  {
    id: "frmtrn",
    title: "Farmer's Training",
    type: "item",
    icon: <img src={farmerTraining} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/frmtrn",
  },
  {
    id: "enttrn",
    title: "Entrepreneurs's Training",
    type: "item",
    icon: <img src={enterprenueshipTraining} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/enttrn",
  },
  {
    id: "tottrn",
    title: "ToT",
    type: "item",
    icon: <img src={tot} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/tottrn",
  },
  {
    id: "psku",
    title: "Demostration",
    type: "item",
    icon: <img src={demonstration} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/psku",
  },

  {
    id: "mchn",
    title: "Machineries",
    type: "item",
    icon: <img src={machineries} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/mchn",
  },
  {
    id: "asst",
    title: "Asset",
    type: "item",
    icon: <img src={asset} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/asst",
  },
  {
    id: "revc",
    title: "Money Receive",
    type: "item",
    icon: <img src={receiveVoucher} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/revc",
  },
  {
    id: "pavc",
    title: "Expenses",
    type: "item",
    icon: <img src={paymentVoucher} style={divStyle} alt="" />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/pavc",
  },

  {
    id: "emin",
    title: "Implementation Unit",
    type: "item",
    icon: <img src={employee} style={divStyle} alt="" />,
    navLink: "/emin",
  },
  {
    id: "demr",
    title: "Location",
    type: "item",
    icon: <img src={locationIcon} style={divStyle} alt="" />,
    navLink: "/demr",
  },

  {
    id: "usin",
    title: "User",
    type: "item",
    icon: <img src={userIcon2} style={divStyle} alt="" />,
    navLink: "/usin",
  },
];

export default navigationConfig;
